export interface Config {
  backendPath: string,
  localizationPath: string;
  inactivityTimeout: number;
}

export function getConfig(): Readonly<Config> {
  return {
    backendPath: '/wtt-backend',
    localizationPath: '/wtt-localization',
    inactivityTimeout: 1000 * 60 * 30
  };
}

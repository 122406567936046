import { TranslationText } from '../../../apis/localization/localization-service';
import _ from 'lodash';

export const MIN_DURATION = 1000;
export const DEFAULT_DURATION = 20000;

export interface Toast {
  message: TranslationText,
  type: ToastType
  /**
   * default: {@link DEFAULT_DURATION}<br>
   * min: {@link MIN_DURATION}
   */
  duration?: number,
}

export type ToastSearch = Omit<Toast, 'duration'>

export type ToastType = 'default' | 'success' | 'error'

export class ToastService {
  showToast(toast: Toast): void {
    window.dispatchEvent(new CustomEvent('wtt.toast.show', { detail: _.cloneDeep(toast) }));
  }

  hideToast(toast: ToastSearch) {
    window.dispatchEvent(new CustomEvent('wtt.toast.hide', { detail: _.cloneDeep(toast) }));
  }
}

export const toastService = new ToastService();
